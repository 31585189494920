import styled from 'styled-components'

const ContentContainer = styled.div`
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.75rem;
    padding-left: 0.75rem;

    @media only screen and (min-width:0em) {
        flex-basis: 100%;
        max-width: 100%;
        display: block;
    }

    @media only screen and (min-width:62em) {
        flex-basis: 75%;
        max-width: 75%;
        display: block;

    }

    p:last-child {
        margin: 0px;
    }
`;

export default ContentContainer
import React from 'react'
import styled from 'styled-components'
import PageSelector from '../forms/pageselector'
import PageSelectorEN from '../forms/pageselector-en'

const HeroMessage = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: 0px 12px;
  position: relative;
  width: 100%;
  z-index: 1;
  max-width: 1280px;
  margin: 0 auto;
  padding: 32px 12px;
  padding-top: 16px;
  @media (min-width:576px) {
  }
`;

const HeroInner = styled.div`
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  @media only screen and (min-width:0em) {
        flex-basis: 100%;
        max-width: 100%;
        display: block;
  }

  @media only screen and (min-width:62em) {
        flex-basis: 75%;
        max-width: 75%;
        display: block;

  }
  p {
    color:rgb(255, 255, 255);
    font-weight: 400;
    line-height: 1.3;
    margin: 0px;
    margin-bottom: ${ props => props.landingpage ? ("16px") : ("16px") } ;
    text-align: center;
    font-size: 18px;
  }
  @media (min-width:576px) {
    padding: 0px 16px;
    p {
      font-size: 21px;
      padding-top: 16px;
    }
  }
  @media (min-width:768px) {
    margin-top: -48px;
  }
`;
const PageTitle= styled.h1`
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.3;
  margin: 0 0 0 0;
  margin-bottom: ${ props => props.landingpage ? ("36px") : ("0") } ;
  text-align: center;
  @media (min-width:768px) {
          font-size: 48px;
  }
  @media (min-width:576px) {
          font-size: 32px;

  }
  @media (min-width:1200px) {
          font-size: 48px;
  }
`;
const PageSubtitle = styled.p`  
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.3;
  margin: 0 0 0 0;
  text-align: center;
`;
const SelectorContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -0.75rem;
  margin-left: -0.75rem;

  @media only screen and (min-width:0em) {
      justify-content: center;
  }

`;

const FormButton = styled.a`
  margin: 12px auto 0px;
  min-width: 155px;
  background-color: #004FEF;
  border-radius: 3px;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 24px;
  text-decoration: none;
  transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  width: auto;
  color: #FFFFFF;
  position: relative;
  background-image: none;

  :hover,
  :active,
  :focus {
    background-color: rgb(25, 96, 240);
  }
`


const PageBannerMessage = props => (
    <HeroMessage>
      <HeroInner landingpage={props.landingpage}>
        {props.supertitle &&  
            <p>{props.supertitle}</p>
        }
        <PageTitle landingpage={props.landingpage}>{props.pageTitle}</PageTitle>
        {props.pageSubtitle &&
            <PageSubtitle>{props.pageSubtitle}</PageSubtitle>
        }
        {props.isHome &&
        <SelectorContainer>
          {props.currentlang ==="de" &&
            <PageSelector />
          }
          {props.currentlang ==="en" &&
            <PageSelectorEN />
          }         
        </SelectorContainer>
        }
        {props.bannerbutton &&
          <FormButton href="#intro" type="button" >
            
            {props.currentlang ==="de" &&
              <React.Fragment>Zu den Angeboten</React.Fragment>
            }
          {props.currentlang ==="en" &&
            
            <React.Fragment>Our Special Offers</React.Fragment>
          } 
          </FormButton>
        }
      </HeroInner>
    </HeroMessage>
  );
  export default PageBannerMessage;

import React from 'react';
import styled from 'styled-components';

const Container = styled.section`
  display: flex;
  position: relative;
  max-height: ${props => props.isHome ? "520px" : "160px"};
  min-height: ${props => props.isHome ? "520px" : "160px"};
  background-position: center;
  background-size: cover;
  

  @media (min-width:576px) {
    height: 60vw;
  }
  @media (min-width:768px) {
    max-height: ${props => props.isHome ? "848px" : "640px"};
    min-height: 640px;
    ::before {
      background-position: center;
      background-size: cover;
      
    }
  }
  ::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: rgba(45, 47, 61, 0.6);
  }
  @media (max-width:767px) {
    margin-top: 64px;
  }
    
`;
const LandingContainer = styled.section`
  display: flex;
  position: relative;
  max-height: ${props => props.isHome ? "520px" : "160px"};
  min-height: ${props => props.isHome ? "520px" : "160px"};
  background-position: center;
  background-size: cover;
  

  @media (min-width:576px) {
    height: 60vw;
  }
  @media (min-width:768px) {
    max-height: 640px;
    min-height: 640px;
    ::before {
      background-position: center;
      background-size: cover;
      
    }
  }
  ::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: rgba(45, 47, 61, 0.6);
  }
  @media (max-width:767px) {
    margin-top: 64px;
  }
    
`;
const Overlay = styled.div`
  width: 100%;
  text-align: center;
  margin: 0px auto;
  position: absolute;
  display: flex;
  text-align: center;
  flex-direction: column;
  min-height: 100%;
`;


function PageBanner(props) {
  const { children, alt, isHome, isLanding, title} = props;
  return (
    <React.Fragment>
      {isLanding ? (
        <LandingContainer>
          <picture className="home">
            <source media="(max-width: 480px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/"+ title +"-768w.webp"}/>
            <source media="(max-width: 480px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/jpg/"+ title +"-768w.jpg"}/>
            <source media="(max-width: 768px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/"+ title +"-768w.webp"}/>
            <source media="(max-width: 768px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/jpg/"+ title +"-768w.jpg"}/>
            <source media="(max-width: 992px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/"+ title +"-992w.webp"}/>
            <source media="(max-width: 992px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/jpg/"+ title +"-992w.jpg"}/>
            <source media="(max-width: 1200px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/"+ title +"-1200w.webp"}/>
            <source media="(max-width: 1200px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/jpg/"+ title +"-1200w.jpg"}/>
            <source media="(min-width: 1201px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/"+ title +"-1980w.webp"}/>
            <source media="(min-width: 1201px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/jpg/"+ title +"-1980w.jpg"}/>
            <img src={"https://res.cloudinary.com/berlitz-augsburg/image/upload/jpg/"+ title +"-1980w.jpg"} alt={alt}/>
          </picture>
          <Overlay>{children}</Overlay>
        </LandingContainer>

      ) : (
        <React.Fragment>
          <Container isHome={isHome} isLanding={isLanding} className={isLanding}>
            {isHome ? (
              <picture className="home">
              <source media="(max-width: 480px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/"+ title +"-768w.webp"}/>
              <source media="(max-width: 480px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/jpg/"+ title +"-768w.jpg"}/>
              <source media="(max-width: 768px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/"+ title +"-768w.webp"}/>
              <source media="(max-width: 768px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/jpg/"+ title +"-768w.jpg"}/>
              <source media="(max-width: 992px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/"+ title +"-992w.webp"}/>
              <source media="(max-width: 992px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/jpg/"+ title +"-992w.jpg"}/>
              <source media="(max-width: 1200px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/"+ title +"-1200w.webp"}/>
              <source media="(max-width: 1200px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/jpg/"+ title +"-1200w.jpg"}/>
              <source media="(min-width: 1201px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/"+ title +"-1980w.webp"}/>
              <source media="(min-width: 1201px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/jpg/"+ title +"-1980w.jpg"}/>
              <img src={"https://res.cloudinary.com/berlitz-augsburg/image/upload/jpg/"+ title +"-1980w.jpg"} alt={alt}/>
            </picture>
            ) : (
              <picture>
              <source media="(max-width: 480px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/"+ title +"-480w.webp"}/>
              <source media="(max-width: 480px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/jpg/"+ title +"-480w.jpg"}/>
              <source media="(max-width: 768px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/"+ title +"-768w.webp"}/>
              <source media="(max-width: 768px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/jpg/"+ title +"-768w.jpg"}/>
              <source media="(max-width: 992px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/"+ title +"-992w.webp"}/>
              <source media="(max-width: 992px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/jpg/"+ title +"-992w.jpg"}/>
              <source media="(max-width: 1200px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/"+ title +"-1200w.webp"}/>
              <source media="(max-width: 1200px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/jpg/"+ title +"-1200w.jpg"}/>
              <source media="(min-width: 1201px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/"+ title +"-1980w.webp"}/>
              <source media="(min-width: 1201px)" srcset={"https://res.cloudinary.com/berlitz-augsburg/image/upload/jpg/"+ title +"-1980w.jpg"}/>
              <img src={"https://res.cloudinary.com/berlitz-augsburg/image/upload/jpg/"+ title +"-1980w.jpg"} alt={alt}/>
            </picture>
            )}
                  <Overlay>{children}</Overlay>    
          </Container>
        </React.Fragment>
      )}
      
    </React.Fragment>
  );
}

export default PageBanner;

import React, {Component} from 'react'
import styled from 'styled-components'
import Container from '../container'
import Row from '../row'
import Checkbox from './checkbox'
import SelectIcon from "../../../assets/selecticon.svg"


const PageContactContainer = styled.div`
  background-color: #004FEF;
  padding: 32px 0;
  align-items: center;
  display: flex;

  @media (min-width:768px) {
    padding: 96px 0;
  }
`;

const PageContactCol = styled.div`
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  padding-bottom: 0;
  text-align: center;

  @media only screen and (min-width:0em) {
        flex-basis: 100%;
        max-width: 100%;
        display: block;
  }
  @media (min-width:768px) {
    padding-bottom: 24px;
  }
  @media only screen and (min-width:62em) {
        flex-basis: 62%;
        max-width: 62%;
        display: block;
  }
`;

const PageContactH2 = styled.h2`
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.3;
  margin: 0 0 12px 0;

  @media (min-width:576px) {
      font-size: 36px;
  }
`;

const PageContactp = styled.p`

  color: #FFFFFF;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.6;
  margin: 0 0 24px 0;
  text-align: center;
  
  @media (min-width:576px) {
        font-size: 18px;
  }
`;
const FormContainer = styled.div`
  box-sizing: border-box;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width:0em) {
    flex-basis: 100%;
    max-width: 100%;
    display: block;

  }

  @media only screen and (min-width:62em) {
    flex-basis: 75%;
    max-width: 75%;
    margin-left: -0.75rem;
    margin-right: -0.75em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Flexcolumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  text-align: left;
  padding: 0;
  margin-bottom: 12px;
  padding-bottom: 12px;

  @media only screen and (min-width:0em) {
    flex-basis: 100%;
    max-width: 100%;
    display: block;
  }

  @media only screen and (min-width:62em) {
    flex: 1;
  }
  @media (min-width:576px) {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}  
`
const FlexCol2 = styled(Flexcolumn)`
 
  @media only screen and (min-width:62em) {
    flex: 2;
  }
`
const CourseInformation = styled.div`
  text-align: left;

  div {
    text-align: left;
    justify-content: left;
  }
  @media (min-width:576px) {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}  
`
const CourseRow = styled(Row)`
  background: #fff;
  padding-top: 28px;
  padding-bottom: 24px;
  justify-content: center;
  margin-bottom: 24px;
  span {
    color: #004FEF;
    font-weight: 400;
    padding-right: 4px;
  }
  div {
    margin-bottom: 0 !important;
  }
  @media only screen and (max-width:767px) {
    padding-left: 12px;
    padding-right: 12px;
  }
  
`
const CfName = styled(Flexcolumn)`
  padding: 0;
  @media (min-width:576px) {
    max-width: 200px !important;
}  
`
const CfModule = styled(Flexcolumn)`
  padding: 0;
  @media (min-width:576px) {
    flex-basis: 50%
}  
`
const CfLevel = styled(Flexcolumn)`
  @media (min-width:576px) {
    max-width: 260px !important;
  }  
  padding: 0;
  @media only screen and (min-width:768px) {
    flex: 2 !important;
  }
`
const CfFocus = styled(Flexcolumn)`

  padding: 0;
`
const CfSchedule = styled(Flexcolumn)`
@media only screen and (min-width:768px) {
  flex: 2 !important;
}
  padding: 0;
  @media (min-width:576px) {
    max-width: 350px !important;
  }  
`
const CfStart= styled(Flexcolumn)`
@media (min-width:576px) {
  max-width: 150px !important;
}  
  padding: 0;
`
const CfModuleStart= styled(Flexcolumn)`
@media (min-width:576px) {
  flex-basis: 30%
}  
  padding: 0;
`
const CourseInfoDiv = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 12px;
  color: inherit;
  font-size: 16px;
  padding-left: 15px;

  p {
    margin: 0px;
  }
`

const FormLabel = styled.label`
  display: block;
  margin-bottom: 12px;
  color: #FFFFFF;
  font-size: 16px;
`
const CourseInfo = styled.span`
  display: block;
  margin-bottom: 12px;
  color: #FFFFFF;
  font-size: 16px;
`
const FormSelectDiv = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`
const FormSelect = styled.select`
  cursor: pointer;
  width: 100%;
  padding: 12px;
  padding-right: 36px;
  border: 1px solid #E8E8E8;
  border-radius: 2px;
  font-size: 16px;
  line-height: 1.3;
  color: inherit;
  background-color: #FFFFFF;
  height: 50px;

  ::-webkit-input-placeholder {
    color: #61626D;
  }

  ::-ms-expand {
    display: none;
  }
`
const FormSelectIcon = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  right: 2px;
  top: 0;
  width: 40px;

  svg {
    font-size: 24px;
  }
`
const FormInput = styled.input`
  background-color: #FFFFFF;
  border-radius: 2px;
  border: 1px solid #E8E8E8;
  color: inherit;
  display: block;
  font-size: 16px;
  padding: 12px;
  width: 100%;

  ::-webkit-input-placeholder {
    color: inherit;
  }
`
const FormMessage = styled.textarea`
  background: #FFFFFF;
  border-radius: 2px;
  border: 1px solid #E8E8E8;
  display: block;
  font-size: 16px;
  max-width: 100%;
  padding: 12px;
  width: 100%;
  min-height: 170px;

  ::-webkit-input-placeholder {
    color: #2D2F3D;
  }
`
const CheckboxLabel = styled.label`
  cursor: pointer;
`
const CheckboxText= styled.span`
  margin-right: 0px;
  color:
  rgb(255, 255, 255);
`
const FormButton = styled.button`
  margin: 12px auto 0px;
  min-width: 155px;
  background-color: #004FEF;
  border-radius: 3px;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 24px;
  text-decoration: none;
  transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  width: auto;
  color: #FFFFFF;
  position: relative;
  border: 2px solid;

  :hover,
  :active,
  :focus {
    background-color: rgb(25, 96, 240);
  }

`

class PageContact extends Component {

  state = { 
    checked: false, 
    value: 0, 

  }

  handleCheckboxChange = event => {
    this.setState({ checked: !this.state.checked });
    this.setState({value: event.target.checked ? 1 : 0});
  }

  render () {
    const language = this.props.currentlang;
    var anchor = "de";
    var recordvalue = null;
    var returl = null;

    if (language === "de"){
      anchor = this.props.anchor;
      returl ="https://www.berlitz-augsburg.de/kontakt/ihre-anfrage/";
    } else {
      anchor = this.props.anchoren;
      returl ="https://www.berlitz-augsburg.de/en/contact/your-inquiry/";
    }

    if (this.props.pagetype === "business") {
      recordvalue = "0121t000000dYn4";
    } else if (this.props.pagetype === "general") {
      recordvalue = "0121t000000dYn5";
    } else if (this.props.pagetype === "language") {
      recordvalue = "0121t000000dYn5";
    } else if (this.props.pagetype === "gefoerdertekurse") {
      recordvalue = "0121t000000dYn5";
    } else if (this.props.pagetype === "kids") {
      recordvalue = "0121t000000dYn6";
    } else if (this.props.pagetype === "courses") {
      recordvalue = "0121t000000dYn5";
    } else if (this.props.pagetype === "coursesv2") {
      recordvalue = "0121t000000dYn5";
    } else if (this.props.pagetype === "onlinecourses") {
      recordvalue = "0121t000000dYn5";
    }
    
    return (
      <PageContactContainer id={anchor}>
      {this.props.currentlang === "de" &&
        <Container>
          <Row>
            <PageContactCol>
              {this.props.pagetype === "language" ? (
                <PageContactH2>Jetzt {this.props.delanguageinterest} lernen mit Berlitz Augsburg</PageContactH2>
              ) : this.props.contactformheadline ? (
                  <PageContactH2>{this.props.contactformheadline}</PageContactH2>
              ) : (
                <PageContactH2>Erfahren Sie mehr</PageContactH2>
               )}
              <PageContactp>Nehmen Sie noch heute Kontakt mit uns auf und stellen Sie uns Ihre Fragen. Ganz unverbindlich, damit wie gemeinsam den Kurs finden, der am besten zu Ihnen passt. Ihr Berlitz Team in Augsburg meldet sich in Kürze bei Ihnen.</PageContactp>
            </PageContactCol>
              <FormContainer>
              <form name="page-contact" action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"  method="POST">
                    <input type="hidden" id="recordType" name="recordType" value={recordvalue} />
                    <input type="hidden" name="oid" value="00D0b000000uiVk"/>
                    <input type="hidden" id="00N0b00000BXXYk" maxlength="255" name="00N0b00000BXXYk" size="20" value="00G3W000000ySYVUA2" />
                    <input type="hidden" id="00N0b00000BXGrY" name="00N0b00000BXGrY" title="Country Segmentation" value="Germany" />
                    <input name="Website_Name__c" type="hidden" value={"https://www.berlitz-augsburg.de"+ this.props.leadsource} />
                    <input name="Form_URL__c" type="hidden" value={"https://www.berlitz-augsburg.de"+ this.props.leadsource} />
                    <input type="hidden" id="url" maxlength="80" name="url" value={"https://www.berlitz-augsburg.de"+ this.props.leadsource}/>
                    {this.props.pagetype ==="onlinecourses" && 
                      <input type="hidden" id="00N0b00000BXGrU" name="00N0b00000BXGrU" value="Online"></input>
                    }
                    {this.props.pagetype ==="coursesv2" && 
                      <input type="hidden" id="00N0b00000BXGrU" name="00N0b00000BXGrU" value="F2F Adult"></input>
                    }
                    <input name="Campaign_Source__c" id="utm_source" type="hidden" value=""></input>
                    <input name="Campaign_Name__c" id="utm_campaign" type="hidden" value=""></input>
                    <input name="Campaign_Medium__c" id="utm_medium" type="hidden" value=""></input>
                    <input name="Experiment__c" id="utm_term" type="hidden" value=""></input>
                    <input type="hidden" name="lead_source" value="Website" />
                    <input type="hidden" id="00N0b00000BXPM9" name="00N0b00000BXPM9" title="Delivery Center" value="Augsburg" />
                    <input name="Time_Zone__c" type="hidden" value="Europe/Berlin" />
                    <input type="hidden" name="retURL" value={returl} />
                    {(this.props.selectedcourse && this.props.pagetype === "gefoerdertekurse") &&
                      <input type="hidden" id="Description2__c" name="Description2__c" value={this.props.selectedcourse.modtitle + " - " + this.props.selectedcourse.modtermine}/>
                    }
                    {(this.props.selectedcourse && this.props.pagetype !== "gefoerdertekurse" && this.props.pagetype !== "courses" && this.props.pagetype !== "coursesv2" && this.props.pagetype !=="onlinecourses") &&
                      <input type="hidden" id="Description2__c" name="Description2__c" value={this.props.selectedcourse.coursename + " - " + this.props.selectedcourse.level}/>
                    }
                    {((this.props.pagetype === "courses" || this.props.pagetype ==="coursesv2" || this.props.pagetype ==="onlinecourses") && this.props.currentlang === "de") &&
                      <input type="hidden" id="00N0b00000BXGt8" name="00N0b00000BXGt8" title="Target Language" value={this.props.enLanguageInterest}/>
                    }
                    {(this.props.selectedcourse && (this.props.pagetype == "coursesv2" || this.props.pagetype ==="onlinecourses") && this.props.currentlang === "de") &&
                      <input type="hidden" id="Description2__c" name="Description2__c" value={this.props.deLanguageInterest + " " + this.props.decoursetype + " " + this.props.selectedLevel + " - " + this.props.selectedDate + " (" + this.props.schedule_de + ")"}/>
                    }
                  {this.props.selectedcourse &&
                    <CourseInformation>
                        <Row> 
                            <CourseInfo>Kursinformation</CourseInfo>
                        </Row>
                      {this.props.pagetype === "gefoerdertekurse" &&
                        <CourseRow> 
                          <CfModule>
                            <span>Englisch für den Beruf:</span>&nbsp;{this.props.selectedcourse.modtitle} 
                          </CfModule>
                          <CfModuleStart>
                            <span>Start:</span>&nbsp;{this.props.selectedcourse.modtermine} 
                          </CfModuleStart>
                        </CourseRow>
                      }
                      {this.props.pagetype === "kids" &&
                        <CourseRow> 
                          <CfModule>
                            <span>Sprache:</span>&nbsp;{this.props.selectedcourse.language} 
                          </CfModule>
                          <CfModuleStart>
                            <span>Altersbereich:</span>&nbsp;{this.props.selectedcourse.agerange} 
                          </CfModuleStart>
                          <CfSchedule>
                            <span>Unterrichtszeiten:</span>&nbsp;{this.props.selectedcourse.schedule} 
                          </CfSchedule>
                          <CfStart>
                            <span>Start:</span>&nbsp;{this.props.selectedcourse.start} 
                          </CfStart>
                        </CourseRow>
                      }
                      {this.props.pagetype === "language" &&
                        <CourseRow> 
                          <CfName>
                            <span>Kursname:</span>&nbsp;{this.props.selectedcourse.coursename} 
                          </CfName>
                          <CfLevel>
                            <span>Sprachlevel:</span>&nbsp;{this.props.selectedcourse.level} 
                          </CfLevel>
                          <CfSchedule>
                            <span>Unterrichtszeiten:</span>&nbsp;{this.props.selectedcourse.schedule} 
                          </CfSchedule>
                          <CfStart>
                            <span>Start:</span>&nbsp;{this.props.selectedcourse.start} 
                          </CfStart>
                        </CourseRow>
                      }
                      {this.props.pagetype === "onlinecourses" &&
                        <CourseRow>
                          {!this.props.selectedDate && this.props.selectedLevel =="Level 1" &&
                          <React.Fragment>
                            <CourseInfoDiv>
                            <p><strong>Live Online {this.props.deLanguageInterest}kurs</strong></p>
                            </CourseInfoDiv>
                          </React.Fragment>
                          }
                          {this.props.selectedLevel && !this.props.selectedDate && this.props.selectedLevel !=="Level 1" &&
                          <React.Fragment>
                            <CourseInfoDiv>
                            <p><strong>Live Online {this.props.deLanguageInterest}kurs ({this.props.selectedLevel})</strong></p>
                            </CourseInfoDiv>
                          </React.Fragment>
                          }
                          {this.props.selectedLevel && this.props.selectedDate  &&
                          <React.Fragment>
                            <CourseInfoDiv>
                            <p><strong>Live Online {this.props.deLanguageInterest}kurs ({this.props.selectedLevel})</strong></p>
                            </CourseInfoDiv>
                              <CourseInfoDiv>
                                {this.props.selectedDate} &nbsp;({this.props.schedule_de})
                              </CourseInfoDiv>
                          </React.Fragment>
                          }
                        </CourseRow>
                      }
                      {this.props.pagetype === "coursesv2" &&
                        <CourseRow>
                          {!this.props.selectedDate && this.props.selectedLevel =="Level 1" &&
                          <React.Fragment>
                            <CourseInfoDiv>
                            {this.props.decoursetype ==="Abendkurs" &&    
                              <p><strong>{this.props.deLanguageInterest} Abendkurs</strong></p>
                            }
                            {this.props.decoursetype ==="Intensivkurs" &&    
                              <p><strong>{this.props.deLanguageInterest} Intensivkurs</strong></p>
                            }
                            </CourseInfoDiv>
                          </React.Fragment>
                          }
                          {(this.props.selectedLevel && !this.props.selectedDate && this.props.selectedLevel !=="Level 1") &&
                          <React.Fragment>
                            <CourseInfoDiv>
                            {this.props.decoursetype ==="Abendkurs" &&    
                              <p><strong>{this.props.deLanguageInterest} Abendkurs ({this.props.selectedLevel})</strong></p>
                            }
                            {this.props.decoursetype ==="Intensivkurs" &&    
                              <p><strong>{this.props.deLanguageInterest} Intensivkurs ({this.props.selectedLevel})</strong></p>
                            }
                            </CourseInfoDiv>
                          </React.Fragment>
                          }
                          {this.props.selectedLevel && this.props.selectedDate  &&
                          <React.Fragment>
                            <CourseInfoDiv>
                            {this.props.decoursetype ==="Abendkurs" &&    
                              <p><strong>{this.props.deLanguageInterest} Abendkurs ({this.props.selectedLevel})</strong></p>
                            }
                            {this.props.decoursetype ==="Intensivkurs" &&    
                              <p><strong>{this.props.deLanguageInterest} Intensivkurs ({this.props.selectedLevel})</strong></p>
                            }
                            </CourseInfoDiv>
                              <CourseInfoDiv>
                                {this.props.selectedDate} &nbsp;({this.props.schedule_de})
                              </CourseInfoDiv>
                          </React.Fragment>
                          }
                        </CourseRow>
                      }
                    </CourseInformation>
                  }
                  {this.props.pagetype === "business" &&
                    <Row>          
                      <Flexcolumn>
                        <FormLabel for="company_contact">Fragen Sie für eine Firma an?</FormLabel>
                        <FormSelectDiv>
                          <FormSelect name="company_contact" id="company_contact">
                            <option selected="" value="">Bitte wählen</option>
                            <option value="1">Ja</option>
                            <option value="0">Nein</option>
                          </FormSelect>
                          <FormSelectIcon>
                            <span>
                              <SelectIcon />
                            </span>
                          </FormSelectIcon>
                        </FormSelectDiv>
                      </Flexcolumn>
                      <Flexcolumn>              
                        <FormLabel for="company">Firma</FormLabel>
                        <FormInput type="text" name="company" id="company" />
                      </Flexcolumn>
                      <Flexcolumn>
                        <FormLabel for="position">Position</FormLabel>
                        <FormInput type="text" name="position" id="position" />
                      </Flexcolumn>
                    </Row>
                  }
                  <Row>          
                    <Flexcolumn>
                      <FormLabel id="salutation" for="salutation">Anrede<span>*</span></FormLabel>
                      <FormSelectDiv>
                        <FormSelect id="salutation" name="salutation" required>
                          <option selected="" value="">Bitte wählen</option>
                          <option value="Ms">Frau</option>
                          <option value="Mr">Herr</option>
                        </FormSelect>
                        <FormSelectIcon>
                          <span>
                            <SelectIcon />
                          </span>
                        </FormSelectIcon>
                      </FormSelectDiv>
                    </Flexcolumn>
                    <Flexcolumn>              
                      <FormLabel for="first_name">Vorname<span>*</span></FormLabel>
                      <FormInput type="text" name="first_name" id="first_name" required />
                    </Flexcolumn>
                    <Flexcolumn>
                      <FormLabel for="last_name">Nachname<span>*</span></FormLabel>
                      <FormInput type="text" name="last_name" id="last_name" required />
                    </Flexcolumn>
                  </Row>
                  <Row>          
                    <FlexCol2>
                      <FormLabel for ="email">E-Mail<span>*</span></FormLabel>
                      <FormInput type="text" name="email" id="email" required/>
                    </FlexCol2>
                    <FlexCol2>
                      <FormLabel for="phone">Telefon</FormLabel>
                      <FormInput type="phone" name="phone" id="phone"/>
                    </FlexCol2>
                    <Flexcolumn>
                        <FormLabel for="zip">PLZ<span>*</span></FormLabel>
                        <FormInput type="zip" name="zip" id="zip" required/>
                    </Flexcolumn>
                  </Row>
                  {this.props.pagetype === "kids" &&
                    <Row>
                      <Flexcolumn>
                        <FormLabel for="language">In welcher Sprache?<span>*</span></FormLabel>
                        <FormSelectDiv>
                          <FormSelect id="00N0b00000BXGt8" name="00N0b00000BXGt8" title="Target Language" required>
                            <option value="">Bitte wählen</option>
                            <option value="English">Englisch</option>
                            <option value="German">Deutsch</option>
                            <option value="Spanish">Spanisch</option>
                            <option value="French">Französisch</option>
                            <option value="Italian">Italienisch</option>
                            <option value="Other">Andere Sprache</option>
                          </FormSelect>
                          <FormSelectIcon>
                            <span>
                              <SelectIcon />
                            </span>
                          </FormSelectIcon>
                        </FormSelectDiv>
                      </Flexcolumn>
                      <Flexcolumn>
                      <FormLabel for="00N0b00000BXGrb">Altersbereich</FormLabel>
                        <FormSelectDiv>
                          <FormSelect id="00N0b00000BXGrb" name="00N0b00000BXGrb" title="Customer Age Range">
                            <option value="">Bitte wählen</option>
                            <option value="4-7">4-7</option>
                            <option value="8-11">8-11</option>
                            <option value="12-15">12-14</option>
                            <option value="16-18">15-17</option>
                          </FormSelect>
                          <FormSelectIcon>
                            <span>
                              <SelectIcon />
                            </span>
                          </FormSelectIcon>
                        </FormSelectDiv>
                      </Flexcolumn>
                    </Row>
                  }
                  {this.props.pagetype === "business" &&
                    <Row>          
                      <Flexcolumn>
                      <FormLabel for="00N0b00000BXGt0">Wofür interessieren Sie sich?<span>*</span></FormLabel>
                        <FormSelectDiv>
                          <FormSelect id="00N0b00000BXGt0" name="00N0b00000BXGt0" required>
                            <option value="">Bitte wählen</option>
                            <option value="Language">Sprachunterricht - Face-to-Face</option>
                            <option value="Online Learning">Sprachunterricht - Online</option>
                            <option value="Gov. Subsidised Programs">Geförderte Weiterbildung für Unternehmen</option>
                            <option value="Testing and Assessment">Einstufung & Sprachtests</option>
                            <option value="Other">Andere</option>
                          </FormSelect>
                          <FormSelectIcon>
                            <span>
                              <SelectIcon />
                            </span>
                          </FormSelectIcon>
                        </FormSelectDiv>
                      </Flexcolumn>
                      <Flexcolumn>
                        <FormLabel for="00N0b00000BXGt8">In welcher Sprache?<span>*</span></FormLabel>
                        <FormSelectDiv>
                          <FormSelect id="00N0b00000BXGt8" name="00N0b00000BXGt8" title="Target Language" required>
                            <option value="">Bitte wählen</option>
                            <option value="English">Englisch</option>
                            <option value="German">Deutsch</option>
                            <option value="Spanish">Spanisch</option>
                            <option value="French">Französisch</option>
                            <option value="Italian">Italienisch</option>
                            <option value="Other">Andere Sprache</option>
                          </FormSelect>
                          <FormSelectIcon>
                            <span>
                              <SelectIcon />
                            </span>
                          </FormSelectIcon>
                        </FormSelectDiv>
                      </Flexcolumn>
                    </Row>
                  }
                  {this.props.pagetype === "general" &&
                    <Row>     
                      <Flexcolumn>
                      <FormLabel for="00N0b00000BXGrU">Wofür interessieren Sie sich?<span>*</span></FormLabel>
                        <FormSelectDiv>
                        <FormSelect id="00N0b00000BXGrU" name="00N0b00000BXGrU" required>
                            <option value="">Bitte wählen</option>
                            <option value="F2F Adult">Sprachunterricht - Face-to-Face</option>
                            <option value="Online">Sprachunterricht - Online</option>
                            <option value="Kids">Kids &amp; Teens Sprachkurse</option>
                            <option value="KidsCamps">Kids &amp; Teens Camps</option>
                            <option value="Subsidized Courses">Geförderte Weitebildung</option>
                            <option value="Study Abroad">Im Ausland lernen</option>
                            <option value="Other">Andere</option> 
                          </FormSelect>
                          <FormSelectIcon>
                            <span>
                              <SelectIcon />
                            </span>
                          </FormSelectIcon>
                        </FormSelectDiv>
                      </Flexcolumn>
                      <Flexcolumn>
                          <FormLabel for="00N0b00000BXGt8">In welcher Sprache?<span>*</span></FormLabel>
                          <FormSelectDiv>
                            <FormSelect id="00N0b00000BXGt8" name="00N0b00000BXGt8" title="Target Language" required>
                            <option value="">Bitte wählen</option>
                            <option value="English">Englisch</option>
                            <option value="German">Deutsch</option>
                            <option value="Spanish">Spanisch</option>
                            <option value="French">Französisch</option>
                            <option value="Italian">Italienisch</option>
                            <option value="Other">Andere Sprache</option>
                          </FormSelect>
                          <FormSelectIcon>
                            <span>
                              <SelectIcon />
                            </span>
                          </FormSelectIcon>
                        </FormSelectDiv>
                      </Flexcolumn>  
                    </Row>
                  }
                  {this.props.pagetype === "language" &&
                    <Row style={{display: this.props.selectedcourse ? 'none' : 'flex' }}>
                      <Flexcolumn>
                        <FormLabel for="00N0b00000BXGrU">Wofür interessieren Sie sich?<span>*</span></FormLabel>
                        <FormSelectDiv>
                          <FormSelect id="00N0b00000BXGrU" name="00N0b00000BXGrU" required={this.props.selectedcourse ? false : true }>
                            <option value="">Bitte wählen</option>
                            <option value="F2F Adult">Sprachunterricht - Face-to-Face</option>
                            <option value="Online">Sprachunterricht - Online</option>
                            <option value="Subsidized Courses">Geförderte Weitebildung</option>
                            <option value="Study Abroad">Im Ausland lernen</option>
                          </FormSelect>
                          <FormSelectIcon>
                            <span>
                              <SelectIcon />
                            </span>
                          </FormSelectIcon>
                        </FormSelectDiv>
                      </Flexcolumn>
                      <Flexcolumn>
                        <FormLabel for="00N0b00000BXGt8">Für welche Sprache interessieren Sie sich?<span>*</span></FormLabel>
                          <FormSelectDiv>
                            <FormSelect id="00N0b00000BXGt8" name="00N0b00000BXGt8" title="Target Language" required={this.props.selectedcourse ? false : true }>
                              <option selected="" value={this.props.enlanguageinterest}>{this.props.delanguageinterest}</option>
                              <option value="English">Englisch</option>
                              <option value="German">Deutsch</option>
                              <option value="Spanish">Spanisch</option>
                              <option value="French">Französisch</option>
                              <option value="Italian">Italienisch</option>
                              <option value="Other">Andere Sprache</option>
                          </FormSelect>
                          <FormSelectIcon>
                            <span>
                              <SelectIcon />
                            </span>
                          </FormSelectIcon>
                        </FormSelectDiv>
                      </Flexcolumn>            
                    </Row>
                  }
                  <Row>          
                    <Flexcolumn>
                      <FormLabel for="description">Nachricht</FormLabel>
                      <FormMessage name="description" id="description"/>
                    </Flexcolumn>
                  </Row>
                  <Row>          
                    <Flexcolumn>
                      <CheckboxLabel>
                        <Checkbox checked={this.state.checked} onChange={this.handleCheckboxChange} value={this.state.value}/>
                        <CheckboxText>Bitte senden Sie mir per E-Mail weitere Informationen und Angebote der Berlitz Sprachschule in Augsburg zu. Meine Einwilligung kann ich jederzeit per E-Mail an datenschutz@berlitz-augsburg.de widerrufen.</CheckboxText>
                      </CheckboxLabel>
                    </Flexcolumn>
                  </Row>
                  <Row>
                    <FormButton type="submit" required>
                          Anfrage senden
                    </FormButton>
                  </Row>
                </form>
              </FormContainer>
          </Row>
        </Container>
      }
      {this.props.currentlang === "en" &&
        <Container>
        <Row>
          <PageContactCol>
            {this.props.pagetype === "language" ? (
                <PageContactH2>Learn {this.props.enlanguageinterest} with Berlitz Augsburg</PageContactH2>
              ) : this.props.contactformheadline ? (
                <PageContactH2>{this.props.contactformheadline}</PageContactH2>
              ) : (
                <PageContactH2>Find out more</PageContactH2>
            )}
              <PageContactp>Contact us today with your questions. We can work together to find the course that’s right for you – with no obligation on your part. Your Berlitz team in Augsburg will contact you shortly.</PageContactp>
            </PageContactCol>
            <FormContainer>
            <form name="page-contact" action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">
                  <input type="hidden" id="recordType" name="recordType" value={recordvalue} />
                  <input type="hidden" name="oid" value="00D0b000000uiVk"/>
                  <input type="hidden" id="00N0b00000BXXYk" maxlength="255" name="00N0b00000BXXYk" size="20" value="00G3W000000ySYVUA2" />
                  <input type="hidden" id="00N0b00000BXGrY" name="00N0b00000BXGrY" title="Country Segmentation" value="Germany" />
                  <input name="Website_Name__c" type="hidden" value={"https://www.berlitz-augsburg.de"+ this.props.leadsource} />
                  <input name="Form_URL__c" type="hidden" value={"https://www.berlitz-augsburg.de"+ this.props.leadsource} />
                  <input type="hidden" id="url" name="url" value={"https://www.berlitz-augsburg.de"+ this.props.leadsource} />
                  {this.props.pagetype ==="onlinecourses" && 
                    <input type="hidden" id="00N0b00000BXGrU" name="00N0b00000BXGrU" value="Online"></input>
                  }
                  {this.props.pagetype ==="coursesv2" && 
                    <input type="hidden" id="00N0b00000BXGrU" name="00N0b00000BXGrU" value="F2F Adult"></input>
                  }
                  <input name="Campaign_Source__c" id="utm_source" type="hidden" value=""></input>
                  <input name="Campaign_Name__c" id="utm_campaign" type="hidden" value=""></input>
                  <input name="Campaign_Medium__c" id="utm_medium" type="hidden" value=""></input>
                  <input name="Experiment__c" id="utm_term" type="hidden" value=""></input>
                  <input type="hidden" name="lead_source" value="Website" />
                  <input type="hidden" id="00N0b00000BXPM9" name="00N0b00000BXPM9" title="Delivery Center" value="Augsburg" />
                  <input type="hidden" name="retURL" value={returl} />
                  {(this.props.selectedcourse && this.props.pagetype === "gefoerdertekurse") &&
                    <input type="hidden" id="Description2__c" name="Description2__c" value={this.props.selectedcourse.modtitle + " - " + this.props.selectedcourse.modtermine}/>
                  }
                  {(this.props.selectedcourse && this.props.pagetype !== "gefoerdertekurse" && this.props.pagetype !== "courses" && this.props.pagetype !== "coursesv2" && this.props.pagetype !=="onlinecourses") &&
                      <input type="hidden" id="Description2__c" name="Description2__c" value={this.props.selectedcourse.coursename + " - " + this.props.selectedcourse.level}/>
                  }
                  {((this.props.pagetype === "courses" || this.props.pagetype ==="coursesv2" || this.props.pagetype ==="onlinecourses") && this.props.currentlang === "en") &&
                    <input type="hidden" id="00N0b00000BXGt8" name="00N0b00000BXGt8" title="Target Language" value={this.props.enLanguageInterest}/>
                  }
                  {(this.props.selectedcourse && (this.props.pagetype == "coursesv2" || this.props.pagetype ==="onlinecourses") && this.props.currentlang === "en") &&
                    <input type="hidden" id="Description2__c" name="Description2__c" value={this.props.enLanguageInterest + " " + this.props.encoursetype + " " + this.props.selectedLevel + " - " + this.props.selectedDate + " (" + this.props.schedule_en + ")"}/>
                  }     
                {this.props.selectedcourse &&
                  <CourseInformation>
                    <Row> 
                        <CourseInfo>Course Information</CourseInfo>
                    </Row>
                    {this.props.pagetype === "gefoerdertekurse" &&
                      <CourseRow> 
                        <CfModule>
                          <span>English for Work:</span>&nbsp;{this.props.selectedcourse.modtitle} 
                        </CfModule>
                        <CfModuleStart>
                          <span>Start:</span>&nbsp;{this.props.selectedcourse.modtermine} 
                        </CfModuleStart>
                      </CourseRow>
                    }
                    {this.props.pagetype === "kids" &&
                        <CourseRow> 
                        <CfModule>
                          <span>Language:</span>&nbsp;{this.props.selectedcourse.language_en} 
                        </CfModule>
                        <CfModuleStart>
                          <span>Age Range:</span>&nbsp;{this.props.selectedcourse.agerange_en} 
                        </CfModuleStart>
                        <CfSchedule>
                          <span>Schedule:</span>&nbsp;{this.props.selectedcourse.schedule_en} 
                        </CfSchedule>
                        <CfStart>
                          <span>Start:</span>&nbsp;{this.props.selectedcourse.start} 
                        </CfStart>
                      </CourseRow>
                    }
                    {this.props.pagetype === "language" &&
                      <CourseRow> 
                      <CfName>
                        <span>Course Name:</span>&nbsp;{this.props.selectedcourse.coursename} 
                      </CfName>
                      <CfLevel>
                        <span>Level:</span>&nbsp;{this.props.selectedcourse.level} 
                      </CfLevel>
                      <CfSchedule>
                        <span>Schedule:</span>&nbsp;{this.props.selectedcourse.schedule} 
                      </CfSchedule>
                      <CfStart>
                        <span>Start:</span>&nbsp;{this.props.selectedcourse.start} 
                      </CfStart>
                    </CourseRow>
                    }
                    {this.props.pagetype === "onlinecourses" &&
                      <CourseRow>
                        {!this.props.selectedDate && this.props.selectedLevel =="Level 1" &&
                        <React.Fragment>
                          <CourseInfoDiv>
                          <p><strong>Live Online {this.props.enLanguageInterest} Course</strong></p>
                          </CourseInfoDiv>
                        </React.Fragment>
                        }
                        {this.props.selectedLevel && !this.props.selectedDate && this.props.selectedLevel !=="Level 1" &&
                        <React.Fragment>
                          <CourseInfoDiv>
                          <p><strong>Live Online {this.props.enLanguageInterest} Course ({this.props.selectedLevel})</strong></p>
                          </CourseInfoDiv>
                        </React.Fragment>
                        }
                        {this.props.selectedLevel && this.props.selectedDate  &&
                        <React.Fragment>
                          <CourseInfoDiv>
                          <p><strong>Live Online {this.props.enLanguageInterest} Course ({this.props.selectedLevel})</strong></p>
                          </CourseInfoDiv>
                            <CourseInfoDiv>
                              {this.props.selectedDate} &nbsp;({this.props.schedule_en})
                            </CourseInfoDiv>
                        </React.Fragment>
                        }
                      </CourseRow>
                    }
                    {this.props.pagetype === "coursesv2" &&
                        <CourseRow>
                          {!this.props.selectedDate && this.props.selectedLevel =="Level 1" &&
                          <React.Fragment>
                            <CourseInfoDiv>
                            {this.props.encoursetype ==="Evening Course" &&    
                              <p><strong>{this.props.enLanguageInterest} Abendkurs</strong></p>
                            }
                            {this.props.encoursetype ==="Intensive Course" &&    
                              <p><strong>{this.props.enLanguageInterest} Intensivkurs</strong></p>
                            }
                            </CourseInfoDiv>
                          </React.Fragment>
                          }
                          {(this.props.selectedLevel && !this.props.selectedDate && this.props.selectedLevel !=="Level 1") &&
                          <React.Fragment>
                            <CourseInfoDiv>
                            {this.props.encoursetype ==="Evening Course" &&    
                              <p><strong>{this.props.enLanguageInterest} Evening Course ({this.props.selectedLevel})</strong></p>
                            }
                            {this.props.encoursetype ==="Intensive Course" &&    
                              <p><strong>{this.props.enLanguageInterest} Intensive Course ({this.props.selectedLevel})</strong></p>
                            }
                            </CourseInfoDiv>
                          </React.Fragment>
                          }
                          {this.props.selectedLevel && this.props.selectedDate  &&
                          <React.Fragment>
                            <CourseInfoDiv>
                            {this.props.encoursetype ==="Evening Course" &&    
                              <p><strong>{this.props.enLanguageInterest} Evening Course ({this.props.selectedLevel})</strong></p>
                            }
                            {this.props.encoursetype ==="Intensive Course" &&    
                              <p><strong>{this.props.enLanguageInterest} Intensive Course ({this.props.selectedLevel})</strong></p>
                            }
                            </CourseInfoDiv>
                              <CourseInfoDiv>
                                {this.props.selectedDate} &nbsp;({this.props.schedule_en})
                              </CourseInfoDiv>
                          </React.Fragment>
                          }
                        </CourseRow>
                      }
                    {this.props.pagetype === "courses" &&
                        <CourseRow> 
                          {!this.props.selectedDate && this.props.selectedLevel =="Level 1" &&
                          <React.Fragment>
                            <CourseInfoDiv>
                            <p><strong>Live Online {this.props.enLanguageInterest} Group Course</strong></p>
                            </CourseInfoDiv>
                          </React.Fragment>
                          }
                          {this.props.selectedLevel && !this.props.selectedDate && this.props.selectedLevel !=="Level 1" &&
                          <React.Fragment>
                            <CourseInfoDiv>
                            <p><strong>Live Online {this.props.enLanguageInterest} Group Course ({this.props.selectedLevel})</strong></p>
                            </CourseInfoDiv>
                          </React.Fragment>
                          }
                          {this.props.selectedLevel && this.props.selectedDate  &&
                          <React.Fragment>
                            <CourseInfoDiv>
                            <p><strong>Live Online {this.props.enLanguageInterest} Group Course ({this.props.selectedLevel})</strong></p>
                            </CourseInfoDiv>
                              <CourseInfoDiv>
                                {this.props.selectedDate} &nbsp;({this.props.schedule_en})
                              </CourseInfoDiv>
                          </React.Fragment>
                          }
                        </CourseRow>
                    }
                  </CourseInformation>
                }
                {this.props.pagetype === "business" &&
                  <Row>          
                    <Flexcolumn>
                      <FormLabel for="company_contact">Is your request on behalf of a company?</FormLabel>
                      <FormSelectDiv>
                        <FormSelect name="company_contact" id="company_contact">
                          <option selected="" value="">Please Select</option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </FormSelect>
                        <FormSelectIcon>
                          <span>
                            <SelectIcon />
                          </span>
                        </FormSelectIcon>
                      </FormSelectDiv>
                    </Flexcolumn>
                    <Flexcolumn>              
                      <FormLabel for="company">Company</FormLabel>
                      <FormInput type="text" name="company" id="company" />
                    </Flexcolumn>
                    <Flexcolumn>
                      <FormLabel for="position">Position</FormLabel>
                      <FormInput type="text" name="position" id="position" />
                    </Flexcolumn>
                  </Row>
                }
                <Row>          
                  <Flexcolumn>
                    <FormLabel id="salutation" for="salutation">Prefix<span>*</span></FormLabel>
                    <FormSelectDiv>
                      <FormSelect id="salutation" name="salutation" required>
                        <option selected="" value="">Please Select</option>
                        <option value="Ms">Ms./Mrs.</option>
                        <option value="Mr">Mr.</option>
                      </FormSelect>
                      <FormSelectIcon>
                        <span>
                          <SelectIcon />
                        </span>
                      </FormSelectIcon>
                    </FormSelectDiv>
                  </Flexcolumn>
                  <Flexcolumn>              
                    <FormLabel for="first_name">First Name<span>*</span></FormLabel>
                    <FormInput type="text" name="first_name" id="first_name" required />
                  </Flexcolumn>
                  <Flexcolumn>
                    <FormLabel for="last_name">Last Name<span>*</span></FormLabel>
                    <FormInput type="text" name="last_name" id="last_name" required />
                  </Flexcolumn>
                </Row>
                <Row>          
                  <FlexCol2>
                    <FormLabel for ="email">E-Mail<span>*</span></FormLabel>
                    <FormInput type="text" name="email" id="email" required/>
                  </FlexCol2>
                  <FlexCol2>
                    <FormLabel for="phone">Phone</FormLabel>
                    <FormInput type="phone" name="phone" id="phone"/>
                  </FlexCol2>
                  <Flexcolumn>
                      <FormLabel for="zip">Zip Code<span>*</span></FormLabel>
                      <FormInput type="zip" name="zip" id="zip" required/>
                  </Flexcolumn>
                </Row>
                {this.props.pagetype === "kids" &&
                  <Row>
                    <Flexcolumn>
                      <FormLabel for="language">Language of interest?<span>*</span></FormLabel>
                      <FormSelectDiv>
                        <FormSelect id="00N0b00000BXGt8" name="00N0b00000BXGt8" title="Target Language" required>
                          <option value="">Please Select</option>
                          <option value="English">English</option>
                          <option value="German">German</option>
                          <option value="Spanish">Spanish</option>
                          <option value="French">French</option>
                          <option value="Italian">Italian</option>
                          <option value="Other">Other Language</option>
                        </FormSelect>
                        <FormSelectIcon>
                          <span>
                            <SelectIcon />
                          </span>
                        </FormSelectIcon>
                      </FormSelectDiv>
                    </Flexcolumn>
                    <Flexcolumn>
                    <FormLabel for="00N0b00000BXGrb">Age Group</FormLabel>
                      <FormSelectDiv>
                        <FormSelect id="00N0b00000BXGrb" name="00N0b00000BXGrb" title="Customer Age Range">
                          <option value="">Please Select</option>
                          <option value="4-7">4-7</option>
                          <option value="8-11">8-11</option>
                          <option value="12-15">12-14</option>
                          <option value="16-18">15-17</option>
                        </FormSelect>
                        <FormSelectIcon>
                          <span>
                            <SelectIcon />
                          </span>
                        </FormSelectIcon>
                      </FormSelectDiv>
                    </Flexcolumn>
                  </Row>
                }
                {this.props.pagetype === "business" &&
                  <Row>          
                    <Flexcolumn>
                    <FormLabel for="00N0b00000BXGt0">What is your service of interest?<span>*</span></FormLabel>
                      <FormSelectDiv>
                        <FormSelect id="00N0b00000BXGt0" name="00N0b00000BXGt0" required>
                            <option value="">Please Select</option>
                            <option value="Language">Language Training - Face to Face</option>
                            <option value="Online Learning">Language Training - Online</option>
                            <option value="Gov. Subsidised Programs">Subsidized Courses for Companies</option>
                            <option value="Testing and Assessment">Language Assessment and Testing</option>
                            <option value="Other">Other</option>
                        </FormSelect>
                        <FormSelectIcon>
                          <span>
                            <SelectIcon />
                          </span>
                        </FormSelectIcon>
                      </FormSelectDiv>
                    </Flexcolumn>
                    <Flexcolumn>
                      <FormLabel for="00N0b00000BXGt8">Language of interest?<span>*</span></FormLabel>
                      <FormSelectDiv>
                        <FormSelect id="00N0b00000BXGt8" name="00N0b00000BXGt8" title="Target Language" required>
                          <option value="">Please Select</option>
                          <option value="English">English</option>
                          <option value="German">German</option>
                          <option value="Spanish">Spanish</option>
                          <option value="French">French</option>
                          <option value="Italian">Italian</option>
                          <option value="Other">Other Language</option>
                        </FormSelect>
                        <FormSelectIcon>
                          <span>
                            <SelectIcon />
                          </span>
                        </FormSelectIcon>
                      </FormSelectDiv>
                    </Flexcolumn>
                  </Row>
                }
                {this.props.pagetype === "general" &&
                  <Row>     
                    <Flexcolumn>
                    <FormLabel for="00N0b00000BXGrU">What is your service of interest?<span>*</span></FormLabel>
                      <FormSelectDiv>
                      <FormSelect id="00N0b00000BXGrU" name="00N0b00000BXGrU" required>
                          <option value="">Please Select</option>
                          <option value="F2F Adult">Language Training - Face to Face</option>
                          <option value="Online">Language Training - Online</option>
                          <option value="Kids">Kids &amp; Teens Language Programs</option>
                          <option value="KidsCamps">Kids &amp; Teens Camps</option>
                          <option value="Subsidized Courses">Subsidized Courses</option>
                          <option value="Study Abroad">Study Abroad</option>
                          <option value="Other">Other</option> 
                        </FormSelect>
                        <FormSelectIcon>
                          <span>
                            <SelectIcon />
                          </span>
                        </FormSelectIcon>
                      </FormSelectDiv>
                    </Flexcolumn>
                    <Flexcolumn>
                      <FormLabel for="00N0b00000BXGt8">Language of interest?<span>*</span></FormLabel>
                        <FormSelectDiv>
                          <FormSelect id="00N0b00000BXGt8" name="00N0b00000BXGt8" title="Target Language" required>
                            <option value="">Please Select</option>
                            <option value="English">English</option>
                            <option value="German">German</option>
                            <option value="Spanish">Spanish</option>
                            <option value="French">French</option>
                            <option value="Italian">Italian</option>
                            <option value="Other">Other Language</option>
                          </FormSelect>
                        <FormSelectIcon>
                          <span>
                            <SelectIcon />
                          </span>
                        </FormSelectIcon>
                      </FormSelectDiv>
                    </Flexcolumn>  
                  </Row>
                }
                {this.props.pagetype === "language" &&
                  <Row style={{display: this.props.selectedcourse ? 'none' : 'flex' }}>
                    <Flexcolumn>
                    <FormLabel for="00N0b00000BXGrU">What is your service of interest?<span>*</span></FormLabel>
                      <FormSelectDiv>
                        <FormSelect id="00N0b00000BXGrU" name="00N0b00000BXGrU" required={this.props.selectedcourse ? false : true }>
                            <option value="">Please Select</option>
                            <option value="F2F Adult">Language Training - Face to Face</option>
                            <option value="Online">Language Training - Online</option>
                            <option value="Kids">Kids &amp; Teens Language Programs</option>
                            <option value="KidsCamps">Kids &amp; Teens Camps</option>
                            <option value="Subsidized Courses">Subsidized Courses</option>
                            <option value="Study Abroad">Study Abroad</option>
                            <option value="Other">Other</option> 
                        </FormSelect>
                        <FormSelectIcon>
                          <span>
                            <SelectIcon />
                          </span>
                        </FormSelectIcon>
                      </FormSelectDiv>
                    </Flexcolumn>
                    <Flexcolumn>
                    <FormLabel for="00N0b00000BXGt8">Language of interest?<span>*</span></FormLabel>
                        <FormSelectDiv>
                        <FormSelect id="00N0b00000BXGt8" name="00N0b00000BXGt8" title="Target Language" required={this.props.selectedcourse ? false : true }>
                          <option selected="" value={this.props.enlanguageinterest}>{this.props.enlanguageinterest}</option>
                          <option value="English">English</option>
                          <option value="German">German</option>
                          <option value="Spanish">Spanish</option>
                          <option value="French">French</option>
                          <option value="Italian">Italian</option>
                          <option value="Other">Other Language</option>
                        </FormSelect>
                        <FormSelectIcon>
                          <span>
                            <SelectIcon />
                          </span>
                        </FormSelectIcon>
                      </FormSelectDiv>
                    </Flexcolumn>            
                  </Row>
                }
                <Row>          
                  <Flexcolumn>
                    <FormLabel for="description">Message</FormLabel>
                    <FormMessage name="description" id="description"/>
                  </Flexcolumn>
                </Row>
                <Row>          
                  <Flexcolumn>
                    <CheckboxLabel>
                      <Checkbox checked={this.state.checked} onChange={this.handleCheckboxChange} value={this.state.value}/>
                      <CheckboxText>I would like to receive special offers via the free Berlitz Augsburg email newsletter. I can revoke my consent at any time by sending an email datenschutz@berlitz-augsburg.de or by using the unsubscribe link in the newsletter.</CheckboxText>
                    </CheckboxLabel>
                  </Flexcolumn>
                </Row>
                <Row>
                  <FormButton type="submit" required>
                    Find out more
                  </FormButton>
                </Row>
              </form>
            </FormContainer>
        </Row>
      </Container>
      }
      </PageContactContainer>
    )
  }
}

export default PageContact;

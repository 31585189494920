import React, {Component} from 'react'
import styled from 'styled-components'
import { Link } from "gatsby"
import NavIcon from "../../../assets/navicon.svg"

const PageSelectContainer = styled.div`
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-top: 10px;

    @media only screen and (min-width:0em) {
        flex-basis: 100%;
        max-width: 100%;
        display: block;
    }
    @media (min-width:576px) {
        font-size: 32px;
        margin-bottom: 30px;  
}
    @media only screen and (min-width:62em) {
        display: block;
    }
`;

const PageSelectInner = styled.div`
    padding: 16px;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.09);
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @media (min-width:768px) {
        justify-content: center;
        padding: 12px;
    }

    @media (min-width:992px) {
        max-width: 100%;
    }
`;

const PageSelectLeftCol = styled.div`
    display: flex;
    width: 100%;
    align-items: baseline;
    font-size: 16px;
    @media (min-width:992px) {
        display: flex;
        width: 100%;
        flex-grow: 0;
        padding: 8px 0;
        align-items: center;
        flex: 1 0 auto;
    }

    @media (min-width:1200px) {
        width: auto;

    }
`;

const PageSelectRightCol = styled.div`
    display: block;
    width: 100%;
    align-items: baseline;
    font-size: 16px;
    @media (min-width:992px) {
        display: flex;
        width: 100%;
        padding: 8px 0;
        align-items: center;
    }

    @media (min-width:1200px) {
        flex-grow: 1;
        width: auto;
        padding-left: 8px;
    }
`;
const PageSelectLeftText = styled.div`
    padding-left: 4px;
    margin-right: 12px;
    margin-bottom: 1px;
    text-align: left;
    flex-shrink: 0;
    color: rgb(45, 47, 61);

    @media (min-width:768px) {
        padding-right: 8px;
        margin-right: 0;
    }
    :first-child {
        padding-top: 2px;
    }

    @media (max-width:992px) {
        margin-top: 12px;
    }
`;

const PageSelectRightText = styled.div`
    padding-left: 4px;
    margin-right: 12px;
    margin-bottom: 1px;
    text-align: left;
    flex-shrink: 0;
    width: 100%;
    margin-bottom: 12px;
    color: rgb(45, 47, 61);

    @media (min-width:768px) {
        padding-right: 8px;
        margin-right: 0;

        :first-child {
            padding-top: 0;
        }
    }

    @media (max-width:992px) {
        margin-top: 12px;
    }

    @media (min-width:768px) {
        width: auto;
        margin-bottom: 0;
    }
`;

const PageSelectLeftSelect = styled.div`
    display: flex;
    margin-bottom: 16px;
    flex-grow: 1;
    margin-bottom: 8px;
    position: relative;
    align-items: center;

    @media (min-width:768px) {
        margin: 0;
    }

    select {
        text-align: left;
        text-align-last: left;
        border-color: #F0F0F0;
    }

    @media (min-width:576px) {
        flex: 1 0 auto;
    }
`;

const PageSelectRightSelect = styled.div`
    display: flex;
    margin-bottom: 16px;
    flex: 1 0 0;
    margin-bottom: 8px;
    position: relative;
    align-items: center;

    @media (min-width:768px) {
        margin: 0;
    }

    select {
        text-align: left;
        text-align-last: left;
        border-color: #F0F0F0;
    }

    @media (min-width:768px) {
        flex: 1 0 auto;
        width: auto;
        margin-right: 16px;
    }

    @media (max-width:992px) {
        margin: 14px auto;
    }

`;
const PageSelectDropdown = styled.select`
    cursor: pointer;
    width: 100%;
    padding: 12px;
    padding-right: 36px;
    border: 1px solid #E8E8E8;
    border-radius: 2px;
    font-size: 16px;
    line-height: 1.3;
    color: #2D2F3D;
    background-color: #FFFFFF;

    ::placeholder {
        color: #61626D;
    }
`;
const IconContainer = styled.div`      
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    right: 2px;
    top: 0;
    width: 40px;
`;

const IconSpan = styled.span`
    display: flex;
    color: rgb(29, 29, 29);
    font-size: 20px;
`;

const PageSelectGoButton = styled(Link)`
    background-color: #004FEF;
    border-radius: 3px;
    color: #FFFFFF;
    cursor: pointer;
    display: inline;
    font-size: 14px;
    font-weight: 500;
    min-width: auto;
    padding: 12px 24px;
    pointer-events: auto;
    position: relative;
    text-decoration: none;
    transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
    width: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 12px;
    font-weight: 500;
    margin-top: 12px;
    transition: 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
    margin-top: 0;
    width: 100%;
    background-image: none;  

    :hover,
    :active,
    :focus {
        background-color: #1960F0;
        color: rgb(255, 255, 255);
    }

    @media (min-width:768px) {
        padding: 12px 40px;
        margin-left: 32px;
        width: auto;
    }

    @media (min-width:768px) {
        width: auto;
        margin-left: 0;
    }
`;

const lookup = {
  "/englisch-lernen/": [
    { id: '/englisch-lernen/', text: 'Englischkursen in Augsburg' },
    { id: '/deutsch-lernen/', text: 'Deutschkursen in Augsburg' },
    { id: '/spanisch-lernen/', text: 'Spanischkursen in Augsburg' },
    { id: '/franzoesisch-lernen/', text: 'Französischkursen in Augsburg' },
    { id: '/italienisch-lernen/', text: 'Italienischkursen in Augsburg' },
    { id: '/andere-sprachen/', text: 'andere Sprachen' }
  ],
  "/kids-und-teens/nachhilfe/": [
    { id: '/kids-und-teens/nachhilfe/', text: 'Einzeltraining für Kinder' },
    { id: '/kids-und-teens/gruppensprachkurse/', text: 'Gruppenkursen für Kinder' },
    { id: '/kids-und-teens/sprachferien/', text: 'Sprachcamps in den Ferien' },
    { id: '/kids-und-teens/programmierkurse/', text: 'Programmierkursen für Kinder' },
  ],
  "/business-service/sprachkurse/": [
    { id: '/business-service/sprachkurse/', text: 'Sprachtrainings' },
    { id: '/berufliche-weiterbildung/unternehmen/', text: 'geförderte Weiterbildung' },
    { id: '/business-service/firmenservice/', text: 'Berlitz Firmenservice' },
  ]
}

class CourseSelector extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dataValue: '/englisch-lernen/',
      selectValue: '/englisch-lernen/'
    };
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }

  onChange = ({ target: { value } }) => {
    this.setState({ dataValue: value });
    this.setState({ selectValue: value });
  }
  handleDropdownChange(e) {
    this.setState({ selectValue: e.target.value });
  }


  render () {
    const { dataValue } = this.state;
    const options = lookup[dataValue];
    var secondselection = this.state.selectValue;
    return (    
      <PageSelectContainer>
        <PageSelectInner>
            <PageSelectLeftCol>
                <PageSelectLeftText>
                    Ich bin
                </PageSelectLeftText>
                <PageSelectLeftSelect>
                    <PageSelectDropdown onChange={this.onChange}>
                      <option value="/englisch-lernen/">ein/e Erwachsene/r</option>
                      <option value="/kids-und-teens/nachhilfe/">ein Elternteil</option>
                      <option value="/business-service/sprachkurse/">ein Unternehmen</option>
                    </PageSelectDropdown>
                    <IconContainer>
                        <IconSpan>
                            <NavIcon />                             
                        </IconSpan>
                    </IconContainer>
                </PageSelectLeftSelect>
            </PageSelectLeftCol>
            <PageSelectRightCol>
                <PageSelectRightText>
                    und ich bin interessiert an
                </PageSelectRightText>
                <PageSelectRightSelect>
                    <PageSelectDropdown onChange={this.handleDropdownChange}>
                      {options.map(o => <option key={o.id} value={o.id}>{o.text}</option>)}
                    </PageSelectDropdown>
                    <IconContainer>
                        <IconSpan>
                            <NavIcon />                             
                        </IconSpan>
                    </IconContainer>
                </PageSelectRightSelect>
                <PageSelectGoButton to={secondselection}>Go</PageSelectGoButton>
            </PageSelectRightCol>
        </PageSelectInner>
      </PageSelectContainer>
    )
  }
}

export default CourseSelector;
